import { Guid } from "./utils.service";

// Warning: Generated code!
export enum UIType {
	TabControl,
	PickListGrid,
	IndividualGrid,
	ControlContainer,
	PickList,
	TextBox,
	TextBoxMulti,
	NumericTextBoxInt,
	NumericTextBoxDec,
	HourMinuteTextBox,
	PickAndAddTwoNum,
	TwoNum,
	RichTextBox,
	TabContainerIndividualGrid
}

// Warning: Generated code!
export class ActivityAppointmentEditCustomerLoadParams {
	editDto: ActivityAppointmentEditDto;
	businessRoleId: number;
}

// Warning: Generated code!
export class ActivityQueryParams {
	startDate: Date;
	endDate: Date;
	professionalId: number;
}

// Warning: Generated code! - TO BE DEVELOPED
export class ResourceHubParams {
	
}

// Warning: Generated code!
export class ResourceHubDto {
	folders: ResourceHubFolderDto[];
}

// Warning: Generated code!
export class ResourceHubFolderDto {
	folderName: string;
	files: ResourceHubFileDto[];
	fileCount: number;
	expanded: boolean = false;
}

// Warning: Generated code!
export class ResourceHubFileDto {
	fileName: string;
	loadedDate: Date;
	id: number;
	mimeType: string;
}

// Warning: Generated code!
export class ActivityTypeAvailabilityEditParams {
	bookingDiaryId: number;
	forDate: Date;
}

// Warning: Generated code!
export class AppointmentDateRangeParams {
	startDate: Date;
	endDateExclusive: Date;
	withAvailabilities: boolean;
}

// Warning: Generated code!
export class AppointmentDeleteParams {
	appointmentId: number;
	appointmentType: AppointmentType;
	activityAppointmentConfirm: DeleteActivityAppointmentConfirmDto;
}

// Warning: Generated code!
export class AppointmentEditParams {
	appointmentId: number;
}

// Warning: Generated code!
export class AvailabilityDateRangeParams {
	startDate: Date;
	endDateExclusive: Date;
}

// Warning: Generated code!
export class AvailabilitySaveParams {
	startDate: Date;
	endDateExclusive: Date;
	edits: AvailabilityEditDto[];
	startTimeEdits: AvailabilityStartTimeEditDto[];
	recurringStatuses: AvailabilityDayRecurringStatusEditDto[];
	availabilityActivityTypes: AvailabilityActivityTypeEditDto[];
	applyToAllWeekdayAvailabilityBookingDiaryIDs: number[];
}

// Warning: Generated code!
export class CustomerSearchParams {
	searchText: string;
}

// Warning: Generated code!
export class GlobalCaseSearchParams {
	searchText: string;
	dateFrom?: Date;
	dateTo?: Date;
	openOnly: boolean;
	serviceTypeId?: number;
	showAll: boolean;
}

// Warning: Generated code!
export class GlobalInvoiceSearchParams {
	searchText: string;
	dateFrom?: Date;
	dateTo?: Date;
	activeOnly: boolean;
	showAll: boolean;
}

// Warning: Generated code!
export class NewAppointmentParams {
	appointmentType: AppointmentType;
	bookingDiaryId: number;
	activityHeaderId?: number;
	startDateTime: Date;
	serviceTypeId?: number;
}

// Warning: Generated code!
export class TimeZoneInfoParams {
	ianaTimeZone: string;
	diaryMsTimeZoneId: string;
	clientMsTimeZoneId: string;
	diaryStartDateTime: Date;
	clientStartDateTime: Date;
	calculateClientTime: boolean;
}

// Warning: Generated code!
export class AttachmentSaveInfoDto {
	activityHeaderId: number;
	noteId: number;
	attachmentId: number;
	fileName: string;
}

// Warning: Generated code!
export abstract class BaseLoadDto {
	loadFailed: boolean;
	warnings: string[];
	error: string;
	errorTitle: string;
}

// Warning: Generated code!
export class IClientEditLoadDto {
	countries: CountryWithStatesLookupDto[];
	organisation: OrganisationDto;
	contractInfo: ContractInfoDto;
}

// Warning: Generated code!
export class ActivityHeaderEditLoadDto extends BaseLoadDto implements IClientEditLoadDto {
	editDto: ActivityHeaderEditDto;
	activityStatuses: ActivityStatusLookupDto[];
	countries: CountryWithStatesLookupDto[];
	noteTypes: NoteTypeLookupDto[];
	referredTos: ReferredToLookupDto[];
	organisation: OrganisationDto;
	isReferredToLookup: boolean;
	isReferredToEditable: boolean;
	isReadOnly: boolean;
	activityEntries: ActivityEntryInfoDto[];
	contractInfo: ContractInfoDto;
	activityHeaderExtended: ActivityHeaderExtendedDto;
}

// Warning: Generated code!
export abstract class ConfirmableWarningEditDto {
	confirmWarnings: boolean;
}

// Warning: Generated code!
export class IClientEditDto {
	fileNumber: number;
	firstName: string;
	lastName: string;
	isDescriptionOnly: boolean;
	description: string;
	communicationAddresses: CommunicationAddressDto[];
	additionalSessions: AdditionalSessionDto[];
	socialServicesContactDetails: string;
	socialServicesContactedDate?: Date;
	isSelfServiceRegistered: boolean;
	activityHeaderExtended: ActivityHeaderExtendedDto;
}

// Warning: Generated code!
export class IOrganisationPartEditDto {
	organisationGroupId?: number;
	organisationDivisionId?: number;
	organisationDepartmentId?: number;
}

// Warning: Generated code!
export class ActivityHeaderEditDto extends ConfirmableWarningEditDto implements IOrganisationPartEditDto {
	activityHeaderId: number;
	fileNumber: number;
	hasExtendedActivityHeader: boolean;
	firstName: string;
	clientPartnerFirstName: string;
	lastName: string;
	clientPartnerLastName: string;
	clientDoB: Date;
	clientDoBChanged: boolean;
	isDescriptionOnly: boolean;
	description: string;
	activityStatusId: number;
	dateOpened: Date;
	dateClosed?: Date;
	referredToText: string;
	referredToId?: number;
	socialServicesContactDetails: string;
	socialServicesContactedDate?: Date;
	isSelfServiceRegistered: boolean;
	organisationGroupId?: number;
	organisationDivisionId?: number;
	organisationDepartmentId?: number;
	customerName: string;
	employerName: string;
	serviceType: string;
	hasStatistics: boolean;
	hasStatisticHeaders: boolean;
	hasStatisticClients: boolean;
	isClosed: boolean;
	isShowClientPartner: boolean;
	additionalSessions: AdditionalSessionDto[];
	communicationAddresses: CommunicationAddressDto[];
	clientPartnerCommunicationAddresses: CommunicationAddressDto[];
	activityNotes: ActivityNoteInfoDto[];
	statisticContainers: StatisticContainerDto[];
	statisticClients: StatisticClientListDto[];
	statisticHeaders: StatisticHeaderListDto[];
	criticalIncidentDetails: CriticalIncidentDetailEditDto;
	activityServiceTypeDetails: ActivityServiceTypeDetailEditDto;
	activityHeaderExtended: ActivityHeaderExtendedDto;
}

// Warning: Generated code!
export class ActivityServiceTypeDetailEditDto {
	typeLabel: string;
	serviceTypeLabel: string;
	type: string;
	description: string;
	numberOfPeopleInvolved: number;
	dateRequestReceived?: Date;
	dateAccountManagerInformed?: Date;
	dateReportSent?: Date;
	dateServiceRequired?: Date;
	supportLocations: SupportLocationDto[];
	involvedPersons: InvolvedPersonDto[];
}

// Warning: Generated code!
export class CriticalIncidentDetailEditDto {
	incidentType: string;
	isReportRequired: boolean;
	isOnsiteSupportRequired: boolean;
	description: string;
	numberOfPeopleInvolved: number;
	dateOfIncident?: Date;
	dateAccountManagerInformed?: Date;
	dateReportSent?: Date;
	supportLocations: SupportLocationDto[];
	involvedPersons: InvolvedPersonDto[];
}

// Warning: Generated code!
export class SupportLocationDto {
	name: string;
	addressLine1: string;
	addressLine2: string;
	addressLine3: string;
	suburb: string;
	city: string;
	postcode: string;
	state: string;
	country: string;
}

// Warning: Generated code!
export class InvolvedPersonDto {
	name: string;
	role: string;
	notes: string;
}

// Warning: Generated code!
export abstract class ActivityEntryInfoBaseDto {
	id: number;
	activityDateTime: Date;
	unitsRaw: number;
	appointmentSessionNumber: number;
	activityServiceDescription: string;
	activityTypeDescription: string;
	isDidNotAttend: boolean;
	wasNoShow: boolean;
	isAppointment: boolean;
	isTime: boolean;
	professionalName: string;
}

// Warning: Generated code!
export class ActivityEntryListInfoDto extends ActivityEntryInfoBaseDto {
}

// Warning: Generated code!
export class ActivityEntryInfoDto extends ActivityEntryInfoBaseDto {
	isCancelledChargeable: boolean;
	appointmentId: number;
	appointmentType: AppointmentType;
	bookingDiaryId: number;
	professionalId: number;
	msOfficeTimeZoneId: string;
	resourceOfficeName: string;
	noteTextRtf: string;
	showNewAppointmentDeleteStyle: boolean;
}

// Warning: Generated code!
export abstract class ActivityNoteBaseDto {
	id: number;
	subject: string;
	noteDateTime: Date;
}

// Warning: Generated code!
export class ActivityNoteInfoDto extends ActivityNoteBaseDto {
	updatedByUser: string;
	updatedDateTime: Date;
}

// Warning: Generated code!
export class ActivityNoteEditDto extends ActivityNoteBaseDto {
	noteTypeId?: number;
	noteContent: string;
	noteAttachments: NoteAttachmentInfoDto[];
}

// Warning: Generated code!
export abstract class NoteAttachmentBaseDto {
	id: number;
	fileName: string;
}

// Warning: Generated code!
export class NoteAttachmentInfoDto extends NoteAttachmentBaseDto {
	noteId: number;
	fileSize: number;
	fileExtension: string;
	updatedDateTime: Date;
	updatedByUser: string;
	urlKey: string;
}

// Warning: Generated code!
export class NoteAttachmentEditDto extends NoteAttachmentBaseDto {
}

// Warning: Generated code!
export class ActivityHeaderExtendedDto {
	id: number;
	medicationTaken: string;
	disabilitiesToConsider: string;
	isPreferPrivateTransport: boolean;
	isPreferPublicTransport: boolean;
	isReviewForEachSession: boolean;
	isReviewForFinalSession: boolean;
	hasUsedServiceBefore: boolean;
	isRedFlag: boolean;
	redFlagAlternativeAppearance?: number;
	redFlagStatusDate?: Date;
	usedService: string;
	usedProfessional: string;
	usedServiceMonth?: Date;
}

// Warning: Generated code!
export class ActivityHeaderSearchResultDto {
	id: number;
	fileNumber: number;
	name: string;
	activityRole: string;
	activityStatus: string;
	serviceType: string;
}

// Warning: Generated code!
export class ActivityQueryDto {
	professionalBusinessOrganisation: string;
	customerBusinessOrganisation: string;
	professionalName: string;
	practice: string;
	isDna: boolean;
	fileNumber?: number;
	activityStatus: string;
	clientName: string;
	employerName: string;
	customerName: string;
	activityType: string;
	activityStartDateTime: Date;
	sessionNumber?: number;
	costRate: number;
	units: number;
	costAmount: number;
	serviceTypeDescription: string;
	serviceDescription: string;
	chargeRateTypeDescription: string;
	bookingResourceName: string;
	professionalInvoiceNumber?: number;
	professionalInvoiceReferenceNumber: string;
}

// Warning: Generated code!
export class ActivityQueryResultDto {
	entries: ActivityQueryDto[];
	excelExportBase64: string;
	excelExportFilename: string;
}


// Warning: Generated code!
export class AppointmentEditLoadDto extends BaseLoadDto implements IClientEditLoadDto {
	editDto: AppointmentEditDto;
	activityTypes: ActivityTypeLookupDto[];
	leaveTypes: LeaveTypeLookupDto[];
	countries: CountryWithStatesLookupDto[];
	timeZones: TimeZoneLookupDto[];
	appointmentHistories: AppointmentHistoryInfoDto[];
	organisation: OrganisationDto;
	availableServices: ServiceLookupDto[];
	contractInfo: ContractInfoDto;
	employers: EmployerLookupDto[];
	activityHeaderExtended: ActivityHeaderExtendedDto;
}

// Warning: Generated code!
export class AppointmentEditResizeOrMoveDto {
	appointmentId: number;
	diaryStartTime: Date;
	diaryEndTime: Date;
	bookingDiaryId: number;
	confirmWarnings: boolean;
	moveReasonId?: number;
}

// Warning: Generated code!
export abstract class AppointmentEditDto extends ConfirmableWarningEditDto {
	appointmentId: number;
	diaryStartTime: Date;
	diaryEndTime: Date;
	bookingDiaryId: number;
	appointmentType: AppointmentType;
	displayInPersonOnlyMessage: boolean;
	extendedDetails: boolean;
	moveReasonId?: number;
}

// Warning: Generated code!
export class PrivateAppointmentEditDto extends AppointmentEditDto {
	appointmentType: AppointmentType;
	name: string;
	location: string;
	notes: string;
	hasRecurrence: boolean;
}

// Warning: Generated code!
export class LeaveAppointmentEditDto extends AppointmentEditDto {
	appointmentType: AppointmentType;
	location: string;
	comment: string;
	leaveTypeId: number;
}

// Warning: Generated code!
export class ActivityAppointmentEditDto extends AppointmentEditDto implements IOrganisationPartEditDto {
	appointmentType: AppointmentType;
	isReadOnly: boolean;
	wasNewActivityHeader: boolean;
	hasExtendedActivityHeader: boolean;
	activityHeaderId: number;
	fileNumber: number;
	createNewActivityHeader: boolean;
	serviceTypeId: number;
	activityTypeId?: number;
	serviceId?: number;
	firstName: string;
	clientPartnerFirstName: string;
	lastName: string;
	clientPartnerLastName: string;
	isDescriptionOnly: boolean;
	description: string;
	isShowClientPartner: boolean;
	contactedSocialServiceDate?: Date;
	showNewAppointmentDeleteStyle: boolean;
	didNotAttend: boolean;
	wasNoShow: boolean;
	isInvoiced: boolean;
	isExcludeAsSession: boolean;
	customerName: string;
	customerNameFromSearch: string;
	employerName: string;
	isCustomerSearchable: boolean;
	customerId?: number;
	employerId?: number;
	organisationGroupId?: number;
	organisationDivisionId?: number;
	organisationDepartmentId?: number;
	notes: string;
	usesTimeZone: boolean;
	clientTimeZoneMinutesOffset: number;
	diaryTimeZoneName: string;
	clientTimeZoneName: string;
	clientSystemTimeZoneName: string;
	clientMsOfficeTimeZoneId: string;
	diaryMsOfficeTimeZoneId: string;
	countryId?: number;
	stateId?: number;
	clientStartTime?: Date;
	clientEndTime?: Date;
	forcedDuration?: number;
	maxDuration?: number;
	additionalSessions: AdditionalSessionDto[];
	communicationAddresses: CommunicationAddressDto[];
	clientPartnerCommunicationAddresses: CommunicationAddressDto[];
	socialServicesContactDetails: string;
	socialServicesContactedDate?: Date;
	isSelfServiceRegistered: boolean;
	hasStatistics: boolean;
	hasStatisticHeaders: boolean;
	statisticContainers: StatisticContainerDto[];
	statisticClients: StatisticClientListDto[];
	statisticHeaders: StatisticHeaderListDto[];
	activityHeaderExtended: ActivityHeaderExtendedDto;
}

// Warning: Generated code!
export class AdditionalSessionDto {
	uniqueId: string;
	requestId: number;
	approvalId: number;
	declineId: number;
	requestedBy: string;
	requestedByBusinessRoleId?: number;
	dateOfRequest: Date;
	primaryConcernAndReason: string;
	issueResolutionAndGoal: string;
	notes: string;
	requestedNumberPerClient: number;
	approvedNumberPerClient: number;
	dateApproved?: Date;
	dateDeclined?: Date;
	approvedBy: string;
	declinedBy: string;
	approvalNotes: string;
	declineNotes: string;
	contractServiceTypeId: number;
	isClientWorking: TriState;
	isRehabilitationCase: TriState;
	isNewIssue: TriState;
	isWorkRelatedIssue: TriState;
	isClientConsentToOrgDiscussion: TriState;
	isClientConsentToReleaseName: TriState;
	isSupported: TriState;
	isClientUsingOtherExternalProfessional: TriState;
	isHelpRequiredDevelopingClientSupportPlan: TriState;
	isHROrManagerRecommended: TriState;
	isLegalIssue: TriState;
	supportedByRole: string;
	supportedByName: string;
}

// Warning: Generated code!
export class AppointmentStartTimeDto {
	dateTime: Date;
}

// Warning: Generated code!
export class AppointmentEndTimeDto {
	dateTime: Date;
	duration: number;
}

// Warning: Generated code!
export class CommunicationAddressDto {
	typeId: number;
	typeName: string;
	canBeForNotifyEmail: boolean;
	showContactButton: boolean;
	showPrimarySecondary: boolean;
	showCanSendMsg: boolean;
	gridViewExtended: boolean;
	gridViewNormal: boolean;
	commAddressId?: number;
	text: string;
	isForNotifyEmail: boolean;
	isForNotifySms: boolean;
	isCanLeaveMessage: boolean;
	isCanSendEmail: boolean;
	isPrimary: boolean;
	isSecondary: boolean;
	canBeForNotifySms: boolean;
	countryId?: number;
	areaCode: string;
	countryVisible: boolean;
	areaCodeVisible: boolean;
	emailVisible: boolean;
}

// Warning: Generated code!
export class ContractInfoDto {
	hasSessions: boolean;
	numberSessionsPerClient: number;
	numberSessionsPerClientOverride?: number;
	contractServiceTypeId: number;
	alertServiceType: string;
	alertContract: string;
	alertCustomer: string;
	socialServicesContactId?: number;
	socialServicesContactInfo: string;
	contractServiceTypeCheckItemValues: ContractServiceTypeCheckItemValueDto[];
}

// Warning: Generated code!
export class ContractServiceTypeCheckItemValueDto {
	text: string;
	value: boolean;
}

// Warning: Generated code!
export class TimeZoneInfoDto {
	isClientInDaylightSavingTime: boolean;
	isDiaryInDaylightSavingTime: boolean;
	clientTimeZoneName: string;
	diaryTimeZoneName: string;
	diaryStartTime: Date;
	clientStartTime: Date;
}

// Warning: Generated code!
export class AppointmentHistoryInfoDto {
	serviceId: number;
	startDateTime?: Date;
	endDateTime?: Date;
	duration?: number;
	professionalId: number;
	professionalName: string;
	bookingResourceName: string;
	bookingResourceId?: number;
	activityType: string;
	isCancelledChargeable: boolean;
	wasNoShow: boolean;
}

// Warning: Generated code!
export enum AppointmentType {
	Activity,
	Private,
	Leave
}

// Warning: Generated code!
export enum TriState {
	Unknown,
	Yes,
	No
}

// Warning: Generated code!
export class AppointmentsDto {
	appointments: AppointmentDto[];
	availabilities: AvailabilityDto[];
	diaryTimeZoneNames: DiaryTimeZoneNameDto[];
	numDays: number;
}

// Warning: Generated code!
export class DiaryTimeZoneNameDto {
	bookingDiaryId: number;
	timeZoneName: string;
}

// Warning: Generated code!
export class AppointmentDto {
	professionalId: number;
	bookingDiaryId: number;
	appointmentId: number;
	activityHeaderId?: number;
	hasExtendedActivityHeader: boolean;
	appointmentType: AppointmentType;
	dateTimeStart: Date;
	dateTimeEnd: Date;
	clientTimeStart?: Date;
	clientTimeZoneName: string;
	bookingResourceName: string;
	fileNumber?: number;
	serviceType: string;
	activityType: string;
	clientNameOrDescription: string;
	customerName: string;
	employerName: string;
	privateAppointmentName: string;
	location: string;
	phoneNumbers: CommAddressDisplayDto[];
	sessionNumber?: number;
	maxSessions?: number;
	isRecurring: boolean;
	isCanelledChargeable: boolean;
	isCouples: boolean;  
	isLocked: boolean;
	isReadOnly: boolean;
	isFixedDuration: boolean;
	excludeAsSession: boolean;
	isReserved: boolean;
	maxDuration?: number;
	zoomInfoUrl: string;
	activityHeaderClosed: boolean;
	isInPerson: boolean;
	isRedFlag: boolean;
	isClientRedFlag: boolean;
	redFlagAlternativeAppearance?: number;
	hideRedFlag: boolean;
	staticalChoiceForDiaryDisplay: string;
	numberMoves?: number;
	moveReasonId?: number;
}

// Warning: Generated code!
export class CommAddressDisplayDto {
	country: string;
	callingCode: number;
	areaCode: string;
	number: string;
}


// Warning: Generated code!
export class AvailabilityActivityTypeEditDto {
	forDate: Date;
	bookingDiaryId: number;
	isRecurring: boolean;
	timeRanges: AvailabilityActivityTypeTimeRangeEditDto[];
}

// Warning: Generated code!
export class AvailabilityActivityTypeTimeRangeEditDto {
	startTime: Date;
	endTime: Date;
	selectedActivityTypeIDs: number[];
}

// Warning: Generated code!
export class AvailabilityDayRecurringStatusEditDto {
	bookingDiaryId: number;
	date: Date;
	isRecurring: boolean;
}

// Warning: Generated code!
export class AvailabilityDto {
	bookingDiaryId: number;
	forDate: Date;
	isRecurring: boolean;
	timeSlotsBase64: string;
	startTimesBase64: string;
	disallowInPersonBase64: string;
	timeRanges: AvailabilityActivityTypeTimeRangeEditDto[];
}

// Warning: Generated code!
export class AvailabilityEditDto {
	bookingDiaryId: number;
	startTime: Date;
	endTime: Date;
	isAvailable: boolean;
	techOnly: boolean;
}

// Warning: Generated code!
export class AvailabilityLoadDto {
	activityTypes: ActivityTypeLookupDto[];
	availabilities: AvailabilityDto[];
	availabilityActivityTypes: AvailabilityActivityTypeEditDto[];
	displayTechOnly: boolean;
}

// Warning: Generated code!
export class AvailabilityStartTimeEditDto {
	bookingDiaryId: number;
	startTime: Date;
	isStartTime: boolean;
}


// Warning: Generated code!
export class ClientErrorDto {
	url: string;
	username: string;
	dateTime: Date;
	message: string;
	stackTrace: string;
	stackTraceError: boolean;
	clientVersion: string;
	environment: BrowserEnvironmentDto;
}

// Warning: Generated code!
export class BrowserEnvironmentDto {
	userAgent: string;
	screenWidth: number;
	screenHeight: number;
	colorDepth: number;
	innerWidth: number;
	innerHeight: number;
}

// Warning: Generated code!
export abstract class CustomStatisticBaseDto {
}

// Warning: Generated code!
export class DeleteActivityAppointmentLoadDto extends BaseLoadDto {
	confirmDto: DeleteActivityAppointmentConfirmDto;
	deleteResult: DeleteResultDto;
}

// Warning: Generated code!
export class DeleteActivityAppointmentConfirmDto {
	warningMessage: DeleteWarningMesage;
	deleteAllowed: boolean;
	canApplyCharge: boolean;
	isCancelledChargeable: boolean;
	allowDelete: boolean;
	applyCharge: boolean;
	wasNoShow: boolean;
	physicalDelete: boolean;
	cancelHoursNotice: number;
	showNewAppointmentDeleteStyle: boolean;
	requiresDeleteReason: boolean;
	activityAppointmentDeleteReasonId?: number;
	activityAppointmentDeleteReasons: DeleteReasonLookupDto[];
	saveResult: AppointmentDto[];
}

// Warning: Generated code!
export enum DeleteWarningMesage {
	MarkedAsCancelledChargeable,
	InvoicedCantDelete,
	ShouldIncurCharge,
	ConfirmDelete
}

// Warning: Generated code!
export class DeleteResultDto {
	saveResult: SaveResult;
	errorMessage: string;
	warnings: string[];
}

// Warning: Generated code!
export class GlobalSearchResultDto {
	max: number;
	exceededMax: boolean;
	items: GlobalSearchResultItemDto[];
}

// Warning: Generated code!
export class GlobalSearchResultItemDto {
	itemType: ItemType;
	id: number;
}

// Warning: Generated code!
export class ActivityHeaderItemDto extends GlobalSearchResultItemDto {
	fileNumber: number;
	name: string;
	customerName: string;
	employerName: string;
	activityRole: string;
	activityStatus: string;
	serviceType: string;
	openedDateTime: Date;
	closedDateTime?: Date;
	isOpen: boolean;
	isClosed: boolean;
	isReferredOn: boolean;
	isClientRedFlag: boolean;
}

// Warning: Generated code!
export class InvoiceItemDto extends GlobalSearchResultItemDto {
	number: number;
	professional: string;
	openedDate: Date;
	releasedDate?: Date;
	approvedDate?: Date;
	paidDate?: Date;
	netAmount: number;
	reference: string;
}

// Warning: Generated code!
export class CustomerItemDto extends GlobalSearchResultItemDto {
	name: string;
	type: string;
	customerNumber: number;
	employerCustomerName: string;
}

// Warning: Generated code!
export enum ItemType {
	ActivityHeader,
	Invoice,
	Customer
}




// Warning: Generated code!
export class LookupDto {
	id: number;
	name: string;
}

// Warning: Generated code!
export class ActivityStatusLookupDto extends LookupDto {
	isOpen: boolean;
	isClosed: boolean;
	isReferredOn: boolean;
}

// Warning: Generated code!
export class ActivityTypeLookupDto extends LookupDto {
	isAppointment: boolean;
	isTimeEntry: boolean;
	isTimeZoneSensitive: boolean;
	isAvailableClientSelfService: boolean;
	isInPerson: boolean;
}

// Warning: Generated code!
export class CommAddrTypeLookupDto extends LookupDto {
	isEmail: boolean;
	isMobile: boolean;
	isPhone: boolean;
}

// Warning: Generated code!
export class CountryLookupDto extends LookupDto {
	hasStates: boolean;
	timeZoneId?: number;
	callingCode?: number;
	iso31661Alpha2Code: string;
}

// Warning: Generated code!
export class CountryWithStatesLookupDto extends CountryLookupDto {
	states: StateLookupDto[];
}

// Warning: Generated code!
export class CriticalIncidentTypeLookupDto extends LookupDto {
}

// Warning: Generated code!
export class CurrencyCodeLookupDto extends LookupDto {
	symbol: string;
}

// Warning: Generated code!
export class LeaveTypeLookupDto extends LookupDto {
}

// Warning: Generated code!
export class NoteTypeLookupDto extends LookupDto {
}

// Warning: Generated code!
export class StateLookupDto extends LookupDto {
	shortName: string;
	timeZoneId?: number;
}

// Warning: Generated code!
export class ReferredToLookupDto extends LookupDto {
}

// Warning: Generated code!
export class ServiceLookupDto extends LookupDto {
	isExcludeAsSession: boolean;
	isInactive: boolean;
	allowedActivityTypeIds: number[];
}

// Warning: Generated code!
export class ServiceTypeLookupDto extends LookupDto {
	isInactive: boolean;
}

// Warning: Generated code!
export class TaxCodeLookupDto extends LookupDto {
	code: string;
	taxRate: number;
}

// Warning: Generated code!
export class TimeZoneLookupDto extends LookupDto {
	msTimeZoneId: string;
	daylightName: string;
	standardName: string;
	utcOffset: number;
	supportsDaylightSavingTime: boolean;
}

// Warning: Generated code!
export class EmployerLookupDto extends LookupDto {
}

// Warning: Generated code!
export class DeleteReasonLookupDto extends LookupDto {
}

export class MoveAppointmentReasonsDto {
	reasons: MoveAppointmentReasonDto[];
}

export class MoveAppointmentReasonDto extends LookupDto {
}

export class AdditionalSessionRequestReasonsDto {
	reasons: AdditionalSessionRequestReasonDto[];
}

export class AdditionalSessionRequestReasonDto extends LookupDto {
}

// Warning: Generated code!
export class OpenActivityHeadersDto {
	openActivityHeaders: OpenActivityHeaderInfoDto[];
}

// Warning: Generated code!
export class OpenActivityHeaderInfoDto {
	id: number;
	fileNumber?: number;
	clientName: string;
	lastActivityDateTime?: Date;
	isIncomplete: boolean;
	isClientRedFlag: boolean;
}

// Warning: Generated code!
export class OpenActivityHeadersCountDto {
	openCount: number;
	incompleteCount: number;
}

// Warning: Generated code!
export class OpenInvoicesDto {
	openInvoices: OpenInvoiceInfoDto[];
}

// Warning: Generated code!
export class OpenInvoiceInfoDto {
	id: number;
	invoiceNumber: number;
	invoiceDate: Date;
	isIncomplete: boolean;
	note: string;
}

// Warning: Generated code!
export class OpenInvoicesCountDto {
	openCount: number;
	incompleteCount: number;
}

// Warning: Generated code!
export class OrganisationDto extends LookupDto {
	organisationGroups: OrganisationGroupDto[];
	isInactive: boolean;
}

// Warning: Generated code!
export class OrganisationGroupDto extends LookupDto {
	organisationDivisions: OrganisationDivisionDto[];
	isInactive: boolean;
}

// Warning: Generated code!
export class OrganisationDivisionDto extends LookupDto {
	organisationDepartments: OrganisationDepartmentDto[];
	isInactive: boolean;
}

// Warning: Generated code!
export class OrganisationDepartmentDto extends LookupDto {
	isInactive: boolean;
}

// Warning: Generated code!
export class ProfessionalInvoiceEditLoadDto extends BaseLoadDto {
	editDto: ProfessionalInvoiceEditDto;
	taxCodes: TaxCodeLookupDto[];
}

// Warning: Generated code!
export class ProfessionalInvoiceEditDto extends ConfirmableWarningEditDto {
	invoiceId: number;
	professionalName: string;
	companyName: string;
	abnNumber: string;
	taxNumber: string;
	number: number;
	referenceNumber: string;
	date: Date;
	amountNet: number;
	amountTax: number;
	amountTotal: number;
	dateReleased?: Date;
	dateApproved?: Date;
	datePaid?: Date;
	releasedBy: string;
	approvedBy: string;
	paidBy: string;
	note: string;
	currencySymbol: string;
	preventAccept: boolean;
	isAccepting: boolean;
	isEditable: boolean;
	canEditRates: boolean;
	downloadInvoicePdfUri: string;
	invoiceLines: ProfessionalInvoiceLineEditDto[];
	showTaxNumbers: boolean;
}

// Warning: Generated code!
export class ProfessionalInvoiceLineEditDto {
	invoiceLineId: number;
	activityHeaderIncomplete: boolean;
	taxCodeId: number;
	date: Date;
	officeShort: string;
	fileNumber?: number;
	customerName: string;
	employerName: string;
	durationText: string;
	unitText: string;
	units: number;
	costRate: number;
	taxRate: number;
	cost: number;
	tax: number;
	total: number;
}

// Warning: Generated code!
export abstract class SaveResultDtoBase {
	saveResult: SaveResult;
	errorMessage: string;
	warnings: string[];
	confirms: string[];
}

// Warning: Generated code!
export class SaveResultDto<T> extends SaveResultDtoBase {
	resultDto: T;
}

// Warning: Generated code!
export enum SaveResult {
	OK,
	Error,
	Warnings
}

// Warning: Generated code!
export abstract class StatisticClientBaseDto {
	id: number;
	anonymousReference: string;
	professionalName: string;
	isIncompleteStatistics: boolean;
}

// Warning: Generated code!
export class StatisticClientListDto extends StatisticClientBaseDto {
}

// Warning: Generated code!
export class StatisticClientEditDto extends StatisticClientBaseDto implements IOrganisationPartEditDto {
	professionalId: number;
	organisationGroupId?: number;
	organisationDivisionId?: number;
	organisationDepartmentId?: number;
	statisticContainers: StatisticContainerDto[];
	statisticClientSessions: StatisticClientSessionDto[];
}

// Warning: Generated code!
export class StatisticClientSessionDto {
	statisticClientSessionId: number;
	owningClientId: number;
	sessionDateTime: Date;
	sessionDurationHours: number;
	sessionDurationMinutes: number;
}

// Warning: Generated code!
export class StatisticContainerDto {
	statisticalGroup: StatisticalGroupDto;
	statistics: StatisticDto[];
}

// Warning: Generated code!
export class StatisticalGroupDto {
	id: number;
	description: string;
	uiTypeName: string;
	isIntTwoValue: boolean;
	isMultiChoice: boolean;
	choiceLimit?: number;
	isOnSiteIndividualSession: boolean;
	statisticalTypes: StatisticalTypeDto[];
}

// Warning: Generated code!
export class StatisticalTypeDto {
	id: number;
	description: string;
	isRequired: boolean;
	descriptionExtraField1: string;
	descriptionExtraField2: string;
	uiTypeName: string;
	isIntTwoValue: boolean;
	statisticalChoices: StatisticalChoiceDto[];
}

// Warning: Generated code!
export class StatisticalChoiceDto {
	id: number;
	description: string;
	isEditBox: boolean;
	statisticalTypeId: number;
}

// Warning: Generated code!
export class StatisticDto {
	id: number;
	isEmpty: boolean;
	statisticalChoiceId: number;
	statisticHeaderId: number;
	statisticalTypeId: number;
	type: string;
	text: string;
	int?: number;
	dec?: number;
	hours?: number;
	minutes?: number;
	intExtra1?: number;
}

// Warning: Generated code!
export abstract class StatisticHeaderBaseDto {
	id: number;
	startDateTime: Date;
	endDateTime: Date;
	professionalId: number;
	professionalName: string;
	createdDateTime?: Date;
	createdByUserId?: number;
	createdByUserName: string;
	updatedDateTime?: Date;
	updatedByUserId?: number;
	updatedByUserName: string;
	isIncompleteStatistics: boolean;
}

// Warning: Generated code!
export class StatisticHeaderListDto extends StatisticHeaderBaseDto {
}

// Warning: Generated code!
export class StatisticHeaderEditDto extends StatisticHeaderBaseDto implements IOrganisationPartEditDto {
	statisticContainers: StatisticContainerDto[];
	organisationGroupId?: number;
	organisationDivisionId?: number;
	organisationDepartmentId?: number;
}

// Warning: Generated code!
export class SystemPreferencesDto {
	useProfessionalInvoiceReferenceNo: boolean;
	useProfessionalInvoiceTaxCodeOverride: boolean;
	useLegacyAppointmentDelete: boolean;
	useTimeZones: boolean;
	mandatoryTimeZoneInAppointment: boolean;
	useSocialServicesControl: boolean;
	isInstallationAustralia: boolean;
	isInstallationNewZealand: boolean;
	isAepCustomInstall: boolean;
	isApmCustomInstall: boolean;
	isEpsCustomInstall: boolean;
	useResouceHub: boolean;
	serverVersion: string;
	allowProfessionalsCreateActivityHeaders: boolean;
	defaultServiceTypeId?: number;
	useReservedAppointments: boolean;
	defaultNumberOfHoursNewActivityTypeAvailability: number;
	customStatisticType: string;
	epsCustomStatisticTabIndex: number;
	caseReadOnlyWhenClosed: boolean;
	defaultCountryId: number;
	defaultCountryName: string;
	defaultCountryCallingCode?: number;
	defaultCurrencyCodeId: number;
	defaultCurrencyCode: string;
	attachmentFileExtensions: string;
	useAppointmentMoveLog: boolean;
	useAdditionalSessionRequestReasons: boolean;
	hidePrivateAppointmentsForEmployees: boolean;
	leaveAppointmentLabel: string;
}

// Warning: Generated code!
export class UserConfigDto {
	forceReloadClient: boolean;
	id: number;
	name: string;
	isPractice: boolean;
	isEmployee: boolean;
	practiceProfessionals: ProfessionalDto[];
	bookingDiaryBookingResources: BookingDiaryBookingResourceDto[];
	serviceTypes: ServiceTypeLookupDto[];
	nonPracticeProfessionalId?: number;
	userGuid: string;
	systemPreferences: SystemPreferencesDto;
	authorisations: AuthorisationsDto;
	maxUploadFileSizeMb: number;
	isProfessionalAvailableClientSelfService: boolean;
	iCalUserUrl: string;
	iCalGuideUrl: string;
}

// Warning: Generated code!
export class AuthorisationsDto {
	allowEditNotes: boolean;
	allowCreateCase: boolean;
}

// Warning: Generated code!
export class ProfessionalDto {
	id: number;
	name: string;
}

// Warning: Generated code!
export class BookingDiaryBookingResourceDto {
	bookingDiaryId: number;
	bookingResourceId: number;
	professionalId: number;
	bookingResourceName: string;
	msTimeZoneId: string;
	professionalName: string;
	bookingResourceIsAvailableClientSelfService: boolean;
	professionalIsAvailableClientSelfService: boolean;
}

// Warning: Generated code!
export class UserLoginResultDto {
	id: number;
	result: UserLoginResult;
	resultMessage: string;
	businessOrganisationId: number;
	isPractice: boolean;
}

// Warning: Generated code!
export enum UserLoginResult {
	OK,
	InvalidApplication,
	PasswordExpired,
	PasswordNotChanged,
	InvalidMode
}

// Warning: Generated code!
export class EapServicesClientSurveyDto extends CustomStatisticBaseDto {
	isFinal: boolean;
	noData: boolean;
	error: number;
	surveyName: string;
	caseNumber: string;
	id: string;
	status: string;
	created: Date;
	expires: Date;
	questionsAsked: number;
	questionsAnswered: number;
	clinicalProfile: ClinicalProfileDto;
	riskProfile: RiskProfileDto;
}

// Warning: Generated code!
export class ClinicalProfileDto {
	concerns: OptionValueDto[];
	goals: OptionValueDto[];
}

// Warning: Generated code!
export class OptionValueDto {
	option: string;
	value: string;
}

// Warning: Generated code!
export class RiskProfileDto {
	total: number;
	riskFactors: RiskOptionValueDto[];
}

// Warning: Generated code!
export class RiskOptionValueDto {
	option: string;
	isAtRisk: boolean;
}
