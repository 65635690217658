import {
	Component,
	OnChanges,
	AfterViewInit,
	ViewChild,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { AdditionalSessionDto, 
		TriState, 
		FormUtils, 
		ConfigService, 
		ProfessionalDto,
		AdditionalSessionRequestReasonsDto,
		AdditionalSessionRequestReasonDto, } from 'app/services';

import { IRadioOption } from 'app/shared';

import { IForm } from 'app/interfaces';

import { TextComponent } from 'app/shared/forms/text.component';

import { BreakifyPipe } from 'app/shared/breakify.pipe';
import { AdditionalSessionStatus } from '../additional-session-status.service';
import { AdditionalSessionEditService } from '../additional-session-edit/additional-session-edit.service';

@Component({
	selector: 'pp-additional-session-edit',
	templateUrl: './additional-session-edit.component.html',
	styleUrls: ['./additional-session-edit.component.scss']
})
export class AdditionalSessionEditComponent implements OnChanges, AfterViewInit, IForm {
	private sub: Subscription;

	form: FormGroup;
	dto: AdditionalSessionDto;
	triStateOptions: IRadioOption[] = [
		{ value: TriState.Yes, name: 'Yes' },
		{ value: TriState.No, name: 'No' },
		{ value: TriState.Unknown, name: 'Unknown', hide: true }
	];

	approvalNotesHtml: SafeHtml;
	declineNotesHtml: SafeHtml;

	professionals: ProfessionalDto[];
	reasons: AdditionalSessionRequestReasonDto[];
	isReasonCombo: boolean = false;
	isReasonManual: boolean = true;

	useCustomPart2 = false;
	hideRehabCase = false;
	hideNewIssue = false;
	hideWorkIssue = false;

	requestedByIsProfessional: Boolean;
	requestedByNonProfessionalName: String;

	@ViewChild('requested', { static: true }) requested: TextComponent;
	@Input() request: AdditionalSessionDto;
	@Input() readOnly: boolean;
	@Output() isDirty = new EventEmitter<boolean>();
	@Output() delete = new EventEmitter<AdditionalSessionDto>();

	constructor(
		private fb: FormBuilder,
		private domSanitizer: DomSanitizer,
		private breakify: BreakifyPipe,
		private configService: ConfigService,
		private cdRef: ChangeDetectorRef,
		private service: AdditionalSessionEditService,
	) {
		this.buildForm();
		this.professionals = configService.professionals;
		this.useCustomPart2 = configService.systemPreferences.isAepCustomInstall;

		this.hideRehabCase = this.hideWorkIssue = this.hideNewIssue =
			configService.systemPreferences.isEpsCustomInstall;

		this.isReasonCombo = false;
		if (this.configService.systemPreferences.useAdditionalSessionRequestReasons){
			this.service.getAdditionalSessionRequestReasons().subscribe((r: AdditionalSessionRequestReasonsDto) => {
				this.reasons = r.reasons;
				this.isReasonCombo = !this.dto.primaryConcernAndReason && this.reasons.length > 0;
				this.isReasonManual = !this.isReasonCombo;
			});
		}		
	}

	ngOnChanges(changes) {
		if (changes.request) {
			this.setData(this.request);
		}

		if (this.sub) {
			this.sub.unsubscribe();
			this.sub = null;
		}
		this.sub = this.form.valueChanges.subscribe(v => {
			if (!this.form.dirty) {
				return;
			}
			this.updateDto();
		});

		if (changes.readOnly) {
			if (this.readOnly) {
				this.form.disable();
			} else {
				this.form.enable();
			}
		}
	}

	ngAfterViewInit() {
		this.setEnabledDisabledAccordingToReversedOrAlreadySaved();
		this.cdRef.detectChanges();
	}

	reasonSelection = (event, dto) => {
		this.form.controls['primaryConcernAndReason'].setValue(event.source.triggerValue);
		this.isReasonManual = true;
		this.isReasonCombo = false;
	}

	setFocus() {
		if (this.dto.requestId === 0) {
			setTimeout(() => {
				this.requested.setFocus();
			}, 0);
		}
	}

	deleteRequest() {
		this.delete.emit(this.dto);
	}

	getForm(): FormGroup {
		return this.form;
	}

	private buildForm() {
		this.form = this.fb.group({
			requestId: [''],
			requestedByBusinessRoleId: ['', [Validators.required]],
			primaryConcernAndReason: [''],
			issueResolutionAndGoal: [''],
			notes: [''],
			requestedNumberPerClient: [''],
			approvedNumberPerClient: [''],
			dateApproved: [''],
			dateDeclined: [''],
			approvedBy: [''],
			declinedBy: [''],
			approvalNotes: [''],
			declineNotes: [''],
			contractServiceTypeId: [''],
			isReversed: [''],
			isClientWorking: undefined,
			isRehabilitationCase: undefined,
			isNewIssue: undefined,
			isWorkRelatedIssue: undefined,
			isClientConsentToOrgDiscussion: undefined,
			isClientConsentToReleaseName: undefined,
			isSupported: undefined,
			isClientUsingOtherExternalProfessional: undefined,
			isHelpRequiredDevelopingClientSupportPlan: undefined,
			isHROrManagerRecommended: undefined,
			isLegalIssue: undefined,
			supportedByRole: undefined,
			supportedByName: undefined
		});
	}

	private updateDto() {
		this.setEnabledDisabledAccordingToReversedOrAlreadySaved();

		FormUtils.applyChanges(this.form, this.dto);
		this.isDirty.emit(true);
	}

	private setEnabledDisabledAccordingToReversedOrAlreadySaved() {
		if (this.readOnly || this.dto.requestId) {
			this.form.disable({ onlySelf: true, emitEvent: false });
		} else {
			this.form.enable({ onlySelf: true, emitEvent: false });
		}
	}

	private setData(dto: AdditionalSessionDto) {
		this.dto = dto;

		this.approvalNotesHtml = this.domSanitizer.bypassSecurityTrustHtml(
			this.breakify.transform(this.request.approvalNotes)
		);
		this.declineNotesHtml = this.domSanitizer.bypassSecurityTrustHtml(
			this.breakify.transform(this.request.declineNotes)
		);

		this.form.patchValue(this.dto);
		this.setEnabledDisabledAccordingToReversedOrAlreadySaved();

		// Force new request to require saving.
		if (!dto.requestId) {
			this.requestedByIsProfessional = true;
			this.isDirty.emit(true);
		} else {
			this.requestedByIsProfessional = !!this.professionals.find(p => p.id === dto.requestedByBusinessRoleId);
			if (!this.requestedByIsProfessional) {
				if (this.configService.config.id === dto.requestedByBusinessRoleId) {
					this.requestedByNonProfessionalName = this.configService.config.name;
				} else {
					this.requestedByNonProfessionalName = 'Non-professional';
				}
			}
		}
	}
}
